import React, { Component } from 'react'
import Layout from '../components/layout/Layout'
import BiographieModule from '../components/BiographieModule'

class App extends Component {
  render() {
    return (
      <>
        <Layout backgroundImage>
          <BiographieModule />
        </Layout>
      </>
    )
  }
}

export default App
