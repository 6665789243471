import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { Fade } from 'react-awesome-reveal'

const BiographieModule = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      image2: file(relativePath: { eq: "photos/photo_peintre.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1000, quality: 79) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image1: file(relativePath: { eq: "huiles/huiles_9.JPG" }) {
        childImageSharp {
          fluid(maxHeight: 1000, quality: 79) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image3: file(relativePath: { eq: "aquarelles/aquarelles_12.JPG" }) {
        childImageSharp {
          fluid(maxHeight: 1000, quality: 79) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image4: file(relativePath: { eq: "huiles/huiles_45.JPG" }) {
        childImageSharp {
          fluid(maxHeight: 1000, quality: 79) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const postStyle = {
    color: 'black',
    backgroundColor: 'white',
    fontSize: 'calc(1rem + 0.5vw)',
    justifyContent: 'center',
    borderRadius: '25px',
    textAlign: 'justify',
  }

  return (
    <Container className="px-5 py-3 my-5 " style={postStyle}>
      <Fade>
        <h1 className="text-center my-5" style={{ color: '#f0ad4e' }}>
          LA VIE DE LÉON HAMONET
        </h1>
        <p>
          Léon Hamonet, ou "le sorcier de la côte d’Émeraude" ainsi que l’avait
          surnommé la journaliste et romancière Marie-Panic Salonne dans un
          article cité dans Ouest-France du 12 avril 1941 en raison de son
          talent à rendre les tons de mauve des bruyères des falaises d’Erquy,
          est né en Bretagne à Erquy dans les côtes d’Armor en 1877 au lieu-dit:
          « La Ville-Bourse »
        </p>

        <Row>
          <Col lg>
            <Img
              fluid={data.image1.childImageSharp.fluid}
              alt="test"
              className="my-3"
            />
          </Col>
          <Col lg>
            <p>
              Son père était capitaine à Bordeaux et s’embarquait de Dahoüet de
              la cale dite aujourd’hui la cale Hamonet. Il a une sœur, Élise,
              mère de l’artiste peintre Yvette L’Héritier décédée en 2004 à
              l’âge de quatre-vingt-dix-sept ans, et deux frères: Georges,
              médecin ORL, et Henri qui, à vingt ans, meurt en mer.
              <br />
              Quand il a quinze ans, sa famille vient habiter Bordeaux où il va
              suivre des cours de dessin; à l’âge de dix-huit ans, il s’inscrit
              aux Beaux-Arts de cette même ville.
              <br />
              En 1903, il part travailler comme employé aux écritures dans la
              Société des sècheries de Port-de-Bouc à Saint-Pierre-et-Miquelon;
              il existe un tableau de cette période qui représente le séchage de
              la morue. Il y restera un an.
            </p>
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
        <Row>
          <Col lg>
            <p>
              En 1909, il se fixe à Erquy, le coin qu’il refusera de quitter par
              la suite. En juin 1910, il épouse Anna L’Hévéder, fille d’un
              notaire de Ploumilliau et ils auront deux enfants, Henry et
              Suzanne. Il passe une année à Pau en 1928 pour accompagner son
              fils Henry souffrant de troubles respiratoires. Dans cette ville,
              il peindra le château, les jardins et les crêtes enneigées des
              Pyrénées avec force, précision et délicatesse. De retour en
              Bretagne, pendant trente ans il peindra avec bonheur et talent la
              côte, de Dahouët au mont Saint-Michel, circulant toujours à
              bicyclette avec son chevalet, parfois accompagné de son petit
              fils, Georges. Dans une correspondance à sa fille, Suzanne, il
              écrit en 1926: « J'essaie de faire quelques vues d’hiver mais,
              grand Dieu, il ne fait pas chaud assis sur un pliant. »
            </p>
          </Col>
          <Col lg>
            <Img
              fluid={data.image2.childImageSharp.fluid}
              alt="test"
              className="my-3"
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <p>
              Il exposera dans de nombreuses galeries et plus particulièrement à
              Saint-Brieuc, notamment à la Galerie Gaudu. Les articles de presse
              de l’époque sont nombreux et élogieux: Ar Gaztenner, journaliste à
              Ouest-France écrit dans ce journal du 12 avril 1941 : « C’est une
              des plus belles parties de notre captivante, capricieuse,
              pittoresque et énigmatique Bretagne que l’on se plaît à voir
              vibrer sous son pinceau de magicien. »
            </p>
          </Col>
        </Row>

        <Row>
          <Col lg>
            <Img
              fluid={data.image3.childImageSharp.fluid}
              alt="test"
              className="my-3"
            />
          </Col>
          <Col lg>
            <p>
              Ses peintures, aquarelles ou huiles sont très colorées, très
              nettes dans leur exécution. Il révèle la beauté des paysages de
              campagne (petites bottes de foin, poules dans un champ, souvent
              accompagnés de petits personnages témoins de leur époque), mais
              plus souvent, il peint la mer avec une voile ou un vol de
              mouettes, les plages et les rochers qu’il observe par tous les
              temps, en toutes saisons et à toutes les heures du jour, ce qui
              explique la richesse des éclairages de ses tableaux.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            C’est aussi un grand portraitiste: il peint sa famille, ses enfants,
            et réalise plusieurs autoportraits à des âges différents, mais il
            n’existe qu’un seul tableau représentant sa femme, alors sa fiancée,
            peinte sur une falaise du cap d’Erquy. Il s’essaie également au
            pastel, notamment pour des commandes de portraits de la part de
            quelques familles amies ou de passage à son l’atelier où il donnait
            également des cours de peinture. Il se rendra à Dinan où il résidera
            une année. Il y peindra et exposera de nombreux tableaux, inspiré
            par les vieilles rues de cette cité médiévale. Son œuvre est immense
            et variée (voir, ci-joint, une liste des œuvres vendues en 1928).
            Hamonet est aussi un grand humoriste, un observateur plein de
            finesse qui s’amuse à « croquer » et caricaturer des personnages de
            son entourage: un frère, « Quat-Bras », un de ses professeurs ou une
            paysanne se rendant au marché avec son panier, comme en témoignent
            les très riches « petits carnets qu’il a laissés.
          </Col>
          <Col lg>
            <Img
              fluid={data.image4.childImageSharp.fluid}
              alt="test"
              className="my-3"
            />
          </Col>
        </Row>
        <p>
          Il écrivait régulièrement à ses enfants pensionnaires à Saint-Brieuc
          et leur racontait la vie du village et de leur famille à l’aide de
          petits dessins, souvent drôles, qui sont un peu des ancêtres de la
          bande dessinée.
        </p>
        <p>
          Pendant cinquante ans, il vivra modestement de sa peinture, scrutant
          pour les peindre, jusqu’à ses derniers instants, les mouettes dans un
          ciel tourmenté ou paisible. Il meurt à l’âge de soixante-seize ans en
          février 1953, à Rennes, chez son fils Henry et sa belle-fille, se
          plaignant de l’absence de mouettes « ici ». Il est enterré au
          cimetière marin d’Erquy où il repose aujourd’hui.
        </p>
        <p>
          Il a eu cinq petits-enfants et douze arrière-petits-enfants. L’une de
          ses petites filles, Marie-Annick, auteure de cet ouvrage, vécut dans
          son atelier à la Ville-Bourse à Erquy. Elle créa en 2004 l’Association
          des amis du peintre Léon Hamonet, qu’elle présida jusqu’en 2008.
          Depuis août 2009, l’un de ses neuf arrière-petits-enfants, Henri
          Savidan, préside l’Association. Jean Raspail et Edmond Hervé, amis de
          la famille et admirateurs du peintre, acceptèrent d’en être membre
          d’honneur.
        </p>
      </Fade>
    </Container>
  )
}

export default BiographieModule
